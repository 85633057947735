ptz-slider {
  &.ptz-slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: $petz-rounded-max;
    background: $petz-color-neutral-white;
    border: $petz-border-sm solid $petz-color-neutral-light;

    &-md {
      width: 32px;
      height: 32px;
    }

    &-lg {
      width: 48px;
      height: 48px;
    }

    &:hover:not(.ptz-slider-disabled) {
      cursor: pointer;
      background: $petz-color-neutral-white;
      border: $petz-border-sm solid $petz-color-neutral-black;
    }

    &-disabled {
      background: $petz-color-neutral-lighter-accent;
      border: $petz-border-sm solid $petz-color-neutral-mid;
    }
  }
}
