import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  Medium = 'md',
  Large = 'lg'
}

enum Orientation {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down'
}

export const PTZSliderConfigList = {
  Size: EnumToArray([Size]),
  Orientation: EnumToArray([Orientation])
};

export const PTZSliderConfig = {
  Size,
  Orientation
};
