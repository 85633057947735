import { Component, Prop, Host, h } from '@stencil/core';
import { PTZSliderTypes } from './types/ptz-slider.types';
import { PTZSliderConfig } from './types/ptz-slider.enums';

@Component({
  tag: `ptz-slider`,
  styleUrl: 'ptz-slider.scss',
  shadow: false
})

export class PTZSlider {
  /** Desabilita o botão */
  @Prop() disabled: boolean = false;

  /** Define a orientação do icone */
  @Prop() orientation: PTZSliderTypes.Orientation;

  /** Define o tamanho */
  @Prop() size?: PTZSliderTypes.Size = PTZSliderConfig.Size.Medium;

  render() {
    const classPtzSlider = {
      'ptz-slider': true,
      [`ptz-slider-${this.size}`]: true,
      'ptz-slider-disabled': this.disabled,
    }

    return (
      <Host class={classPtzSlider}>
        <ptz-icon
          brand='ptz'
          variant='solid'
          name={`angle-${this.orientation}-solid`}
          size={this.size === PTZSliderConfig.Size.Medium ? 'lg' : '2xl'}
          color={this.disabled ? "neutral-mid" : "neutral-black"}></ptz-icon>
      </Host>
    );
  }
}
